/** 语言包key值请参考vant3，网址：https://vant-contrib.gitee.io/vant/#/zh-CN/locale **/
export default {
    'en-US': {
        'copy-success': 'Copy successful!',
        'title': 'NFT Market',

        'link_the_wallet':'Please link the wallet and sign it first',
        'Introduction':'Introduction',
        'desc':'OYE is an innovative blockchain project launched by DBS Bank, a well-known financial institution in Singapore, and many encryption institutions. It is committed to integrating DeFi, GameFi and MemeFi, realizing the free flow of assets through cross-chain technology, and creating decentralization, entertainment and transparency. The financial ecology of.',
        '静态收益':'Static',
        '动态收益':'Dynamic',
        '团队收益':'Team',
    },
    'vi-VN': {
        'copy-success': 'Sao chép thành công!',
        'title': 'NFT市场',
        'mybuy': '我的认购',
        'buynum': '认购数量',
        'inviteNum': '共建数据',
        'inviteAmount': '共建流量',
        'gongxianusdt': '贡献usdt',
        'My Reward': '我的奖励',

        'link_the_wallet':'Vui lòng liên kết ví và ký tên trước.',
        'Introduction':'Giới thiệu',
        'desc':'OYE là một dự án blockchain sáng tạo được khởi xướng bởi Ngân hàng DBS, một tổ chức tài chính nổi tiếng ở Singapore và nhiều tổ chức tiền điện tử. Nó cam kết tích hợp DeFi, GameFi và MemeFi, đạt được dòng tài sản tự do thông qua công nghệ chuỗi chéo và tạo ra một hệ sinh thái tài chính phi tập trung, giải trí và',
        'Please register first':'Vui lòng đăng ký trước.',
        'Invitation code':'Mã lời mời',
        'Please fill in the invitation code':'Vui lòng điền mã lời mời.',
        'Click to register':'Nhấp để đăng ký',
        'Deposit':'Đã cam kết',
        'Please select the deposit amount':'Vui lòng nhập số lượng cam kết',
        'Amount: min ':'Số lượng tối thiểu ',
        'Balance':'Sự cân bằng',
        'not started':'Nó sắp bắt đầu',
        'In deposit':'Trong cam kết',
        'Count down':'Đếm',
        'Redeem':'Chuộc lỗi',
        'My stake':'Lời hứa của tôi',
        'Static':'Tĩnh',
        'Dynamic':'Tĩnh',
        'Team income':'Lợi ích của nhóm',
        'Withdrawable':'Có thể rút tiền mặt',
        'Withdrawn':'Tiền mặt',
        'Withdraw':'Rút tiền',
        'Income record':'Hồ sơ thu nhập',
        'My team':'Đội của tôi',
        'Direct num':'Đẩy trực tiếp',
        'Total Stake':'Cầm cố',
        'Big Region Amount':'Hiệu suất khu vực',
        'Small Region Amount':'Hiệu suất cộng đồng',
        'Level':'Xếp hạng',
        'Get invitation link after participating in pre-sale':'Nhận liên kết lời mời sau khi tham gia vào cam kết',
        'Copy link':'Sao chép liên kết',
        'Recommended List':'Danh sách các khuyến nghị',
        'time':'Thời',
        'amount':'Số lượng',
        'no more':'Chưa có kỷ lục nào',
        'Invite num':'Đẩy trực tiếp',
        'Home':'Trang chủ',
        'Total':'Tất cả',
        'confirm':'Xác nhận',
        'cancel':'Bãi bỏ',
        'amount must be gt 0':'Số lượng phải lớn hơn 0',
        'amount max ':'Con số lớn nhất',
        'Please approve':'Vui lòng cho phép',
        'Please confirm':'Vui lòng xác nhận',
        'Successful':'Sự thành công',
        'Insufficient withdrawal balance in the system':'Phản ánh sự cân bằng không đủ',
        'Submitting...':'Đệ trình',
        'Confirm withdraw':'Xác nhận phương án',
        'Partnerships':'Đối tác',
        'Billion Crypto Wealth Awaits Your Participation':'Hàng tỷ USD tài sản tiền điện tử đang chờ bạn tham gia',
        'memo':'Ghi chú',
        '静态收益':'Thu nhập tĩnh',
        '动态收益':'Thu nhập năng động',
        '团队收益':'Thu nhập của nhóm',
    },
    'th-TH': {
        'copy-success': 'คัดลอกสําเร็จแล้ว!',
        'title': 'NFT市场',
        'mybuy': '我的认购',
        'buynum': '认购数量',
        'inviteNum': '共建数据',
        'inviteAmount': '共建流量',
        'gongxianusdt': '贡献usdt',
        'My Reward': '我的奖励',

        'link_the_wallet':'กรุณาเชื่อมโยงกระเป๋าเงินและเซ็นชื่อก่อน',
        'Introduction':'แนะนําสั้น ๆ',
        'desc':'OYE เป็นโครงการบล็อกเชนที่เป็นนวัตกรรมใหม่ที่เปิดตัวโดย DBS Bank ซึ่งเป็นสถาบันการเงินที่มีชื่อเสียงในสิงคโปร์ ร่วมกับสถาบันการเข้ารหัสหลายแห่ง มุ่งมั่นที่จะรวม DeFi, GameFi และ MemeFi ตระหนักถึงการไหลของสินทรัพย์อย่างอิสระผ่านเทคโนโลยีข้ามเครือข่าย และสร้างระบบนิเวศทางการเงินแบบกระจายอํานาจ สนุกสนาน และโปร่งใส',
        'Please register first':'กรุณาลงทะเบียนก่อน',
        'Invitation code':'รหัสคําเชิญ',
        'Please fill in the invitation code':'โปรดกรอกรหัสเชิญ',
        'Click to register':'คลิกเพื่อลงทะเบียน',
        'Deposit':'คำมั่นสัญญา',
        'Please select the deposit amount':'กรุณาใส่จํานวนจํานํา',
        'Amount: min ':'ปริมาณขั้นต่ํา ',
        'Balance':'ความสมดุล',
        'not started':'มันกําลังจะเริ่มต้นขึ้น',
        'In deposit':'อยู่ระหว่างการจํานํา',
        'Count down':'การนับถอยหลัง',
        'Redeem':'การไถ่ถอน',
        'My stake':'คํามั่นสัญญาของฉัน',
        'Static':'คงที่',
        'Dynamic':'พลวัต',
        'Team income':'รายได้ของทีม',
        'Withdrawable':'เงินสดสามารถถอนได้',
        'Withdrawn':'เงินสด',
        'Withdraw':'ถอน',
        'Income record':'บันทึกรายได้',
        'My team':'ทีมของฉัน',
        'Direct num':'ดันตรง',
        'Total Stake':'คำมั่นสัญญา',
        'Big Region Amount':'ผลการดําเนินงานระดับภูมิภาค',
        'Small Region Amount':'ประสิทธิภาพของชุมชน',
        'Level':'ชั้น',
        'Get invitation link after participating in pre-sale':'รับลิงก์คําเชิญหลังจากเข้าร่วมในคํามั่นสัญญา',
        'Copy link':'คัดลอกลิงก์',
        'Recommended List':'รายการแนะนํา',
        'time':'จํานวนคน',
        'amount':'จํานวนคน',
        'no more':'ยังไม่มีบันทึก',
        'Invite num':'ดันโดยตรง',
        'Home':'หน้าแรก',
        'Total':'ล้วน',
        'confirm':'การยืนยัน',
        'cancel':'ยกเลิกแล้ว',
        'amount must be gt 0':'ปริมาณต้องมากกว่า 0',
        'amount max ':'จํานวนมากที่สุด',
        'Please approve':'โปรดอนุญาต',
        'Please confirm':'กรุณายืนยัน',
        'Successful':'ความสําเร็จ',
        'Insufficient withdrawal balance in the system':'สะท้อนความสมดุลที่ไม่เพียงพอ',
        'Submitting...':'กําลังส่ง',
        'Confirm withdraw':'ยืนยันศูนย์รวม',
        'Partnerships':'หุ้นส่วน',
        'Billion Crypto Wealth Awaits Your Participation':'ความมั่งคั่งของ Crypto พันล้านรอการเข้าร่วมของคุณ',
        'memo':'หมายเหตุโดย',
        '静态收益':'คงที่',
        '动态收益':'พลวัต',
        '团队收益':'คณะ',
    },
    'zh-TW': {
        'copy-success': '複製成功!',
        'title': 'NFT市场',
        'mybuy': '我的认购',
        'buynum': '认购数量',
        'inviteNum': '共建数据',
        'inviteAmount': '共建流量',
        'gongxianusdt': '贡献usdt',
        'My Reward': '我的奖励',

        'link_the_wallet':'請先鏈接錢包並簽名',
        'Introduction':'简介',
        'desc':'OYE 歐耶是由新加坡知名金融機構DBS Bank聯合衆多加密機構發起的創新區塊鏈項目，致力於將DeFi、GameFi、MemeFi融爲一體，通過跨鏈技術實現資產的自由流動，打造去中心化、娛樂化、透明化的金融生態。',
        'Please register first':'請先注冊',
        'Invitation code':'邀請碼',
        'Please fill in the invitation code':'請填寫邀請碼',
        'Click to register':'點擊注冊',
        'Deposit':'質押',
        'Please select the deposit amount':'請輸入質押數量',
        'Amount: min ':'最小數量',
        'Balance':'餘額',
        'not started':'即將開始',
        'In deposit':'質押中',
        'Count down':'倒計時',
        'Redeem':'贖回',
        'My stake':'我的質押',
        'Static':'靜態',
        'Dynamic':'動態',
        'Team income':'團隊收益',
        'Withdrawable':'可領取',
        'Withdrawn':'已領取',
        'Withdraw':'領取',
        'Income record':'收益記錄',
        'My team':'我的團隊',
        'Direct num':'直推人數',
        'Total Stake':'縂質押',
        'Big Region Amount':'大區業績',
        'Small Region Amount':'小區業績',
        'Level':'等級',
        'Get invitation link after participating in pre-sale':'參與質押后獲取邀請鏈接',
        'Copy link':'複製鏈接',
        'Recommended List':'推薦列表',
        'time':'時間',
        'amount':'數量',
        'no more':'暫無記錄',
        'Invite num':'直推',
        'Home':'首頁',
        'Total':'全部',
        'confirm':'確認',
        'cancel':'取消',
        'amount must be gt 0':'數量必須大於0',
        'amount max ':'數量最大',
        'Please approve':'請授權',
        'Please confirm':'請確認',
        'Successful':'成功',
        'Insufficient withdrawal balance in the system':'領取餘額不足',
        'Submitting...':'提交中...',
        'Confirm withdraw':'確認領取',
        'Partnerships':'合作夥伴',
        'Billion Crypto Wealth Awaits Your Participation':'數十億加密財富等待您的參與',
        'memo':'備注',
        '静态收益':'靜態收益',
        '动态收益':'動態收益',
        '团队收益':'團隊收益',
    },


    'zh-CN': {
        'copy-success': 'Copy successful!',
        'title': 'NFT市场',
        'mybuy': '我的认购',
        'buynum': '认购数量',
        'inviteNum': '共建数据',
        'inviteAmount': '共建流量',
        'gongxianusdt': '贡献usdt',
        'My Reward': '我的奖励',

        'Please link the wallet and sign it first':'',
    },

    // ...
}
