import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '../assets/images/lang.png';

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1605f86b"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "langbox"
};
var _hoisted_2 = {
  key: 0,
  class: "langList"
};
var _hoisted_3 = ["value", "onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
    src: _imports_0,
    alt: "",
    class: "langIco",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $options.openLang();
    })
  }), $data.showLang ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.localeList, function (lang, index) {
    return _openBlock(), _createElementBlock("div", {
      class: "langItem",
      key: index,
      value: lang,
      onClick: function onClick($event) {
        return $options.onSwitchLang(index);
      }
    }, _toDisplayString(lang), 9, _hoisted_3);
  }), 128))])) : _createCommentVNode("", true)]);
}