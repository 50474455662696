import axios from 'axios'
import store from '../store'

const service = axios.create({
    headers: {'content-type': 'application/json'},
    //baseURL: process.env.NODE_ENV === 'production' ? publicConfig.BASE_API : process.env.VUE_APP_API_BASE_URL,
    baseURL: 'https://sys.oyecoin.com/',  // http://sys.okokcoin.net/
    timeout: 60 * 1000
})

service.interceptors.request.use(config => {
    // Content-Type
    config.headers['Content-Type'] = 'application/json;charset=utf-8'
    config.headers['token'] = localStorage.getItem('token');
    config.headers['accept-language'] = localStorage.getItem('lang');
    //config.headers['token'] = "64fb1bea-1723-4be5-ac3c-d920ca4ad1a3"

    return config
})

service.interceptors.response.use((response) => {
    const result = response.data
    // api error
    if (response.status !== 200 || !result) {
        return Promise.reject(response)
    }
    return result
}, (error) => {
    // network error
    // const errMsg = ((error.response || {}).data || {}).message || 'There was an error in the network request. Please try again later!'
    return Promise.reject(error)
})

const installer = {
    vm: {},
    install (Vue) {
        Vue.use(VueAxios, service)
    }
}

export {
    installer as VueAxios,
    service as request
}
